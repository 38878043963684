@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500');

body {
  background-color: white;
  margin: 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
  font-family: 'Montserrat', Helvetica;
}

html {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}